<template>
  <div class="contectus">
    <!-- <p class="text_title">Contact Us</p> -->
    <div class="input_concent">
      <div class="input_item">
        <!-- <img src="./img/user_input.png" alt="" width="100%"> -->
        <input
          type="text"
          v-model="insertInfo.user"
          placeholder="Please enter name"
        />
      </div>
      <div class="input_item">
        <!-- <img src="./img/email_input.png" alt="" width="100%"> -->
        <input
          type="text"
          v-model="insertInfo.email"
          placeholder="Please enter E-mail"
        />
      </div>
      <div class="input_item">
        <!-- <img src="./img/phone_input.png" alt="" width="100%"> -->
        <input
          type="text"
          v-model="insertInfo.phone"
          placeholder="Please enter Phone"
        />
      </div>
    </div>
    <textarea
      class="message_input"
      type="textarea"
      placeholder="Please enter Message"
      v-model="insertInfo.message"
    ></textarea>
    <el-button class="send" :plain="true" @click="thro">Send</el-button>
    <div class="cusinfo">
      <p>Customer care number: +91 9917383242</p>
      <p>Customer care email ID:contact@rayago.com</p>
      <p>AFIGHT TECHNOLOGIES PRIVATE LIMITED</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      insertInfo: {
        user: '',
        email: '',
        phone: '',
        message: '',
      },
      timer: null,
      flag: false,
    };
  },
  methods: {
    throttle(func, wait = 300, immediate = true) {
      if (immediate) {
        if (!this.flag) {
          this.flag = true;
          // 如果是立即执行，则在wait毫秒内开始时执行
          typeof func === 'function' && func();
          this.timer = setTimeout(() => {
            this.flag = false;
          }, wait);
        }
      } else {
        if (!flag) {
          this.flag = true;
          // 如果是非立即执行，则在wait毫秒内的结束处执行
          this.timer = setTimeout(() => {
            this.flag = false;
            typeof func === 'function' && func();
          }, wait);
        }
      }
    },
    thro() {
      this.throttle(this.SendMessage, 5000);
      // this.throttle(this.SendMessage, 1000)
    },

    SendMessage() {
      if (
        this.insertInfo.user == '' ||
        this.insertInfo.email == '' ||
        this.insertInfo.phone == '' ||
        this.insertInfo.message == ''
      ) {
        this.$message({
          message: 'Please fill in all the information.',
          type: 'warning',
          //type: warning
          // this.$message.error('shibai');
        });
        return;
      }
      this.insertInfo.user = '';
      this.insertInfo.email = '';
      this.insertInfo.phone = '';
      this.insertInfo.message = '';
      this.$message({
        message: 'send successfully',
        type: 'success',
        //type: warning
        // this.$message.error('shibai');
      });
    },
  },
};
</script>

<style lang="scss" scoped src="./contactus.scss">
</style>